// Third-party imports
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { connectRouter } from 'connected-react-router';

// Croner imports
import accountSettingsReducer from './accountSettings';
import authReducer from './authentication';
import contactReducer from './contact';
import documentReducer from './documents';
import homeReducer from './home';
import messagesReducer from './messages';
import participationReducer from './participation';
import resultsReducer from './results';
import filesReducer from './files'; 

const createRootReducer = (history) =>
  combineReducers({
    form,
    router: connectRouter(history),
    accountSettings: accountSettingsReducer,
    auth: authReducer,
    contact: contactReducer,
    documents: documentReducer,
    home: homeReducer,
    messages: messagesReducer,
    participation: participationReducer,
    results: resultsReducer,
    files: filesReducer,
  });

export default createRootReducer;
