import axios from 'axios';

// Croner Import
import {
  demoPosListData,
  demoQueryData,
} from '../components/Demo/data/demoData';

// Establish the different types
export const CHANGED = 'changed';
export const COUNTRY = 'country';
export const FILTER_RESULTS = 'filter_results';
export const PDF_PRINT = 'pdf_print';
export const POS_COUNT = 'pos_count';
export const POS_TITLE = 'pos_title';
export const QUERY = 'query';
export const RESULTS = 'results';
export const RESULTS_DOWNLOAD_ERROR = 'results_download_error';
export const RESULTS_DOWNLOAD_PROGRESS = 'results_download_progress';
export const RESULTS_DOWNLOAD_STATUS = 'results_download_status';
export const TERM = 'term';

export function basicQueryResults(cuts) {
  return function (dispatch) {
    dispatch({
      type: FILTER_RESULTS,
      payload: cuts,
    });
  };
}

export function clearCuts() {
  return function (dispatch) {
    dispatch({
      type: FILTER_RESULTS,
      payload: '',
    });
  };
}

// Clear out the store when going away from /resuls containers
export function clearResults() {
  return function (dispatch) {
    dispatch({
      type: FILTER_RESULTS,
      payload: '',
    });
    dispatch({
      type: QUERY,
      payload: '',
    });
    dispatch({
      type: RESULTS,
      payload: '',
    });
    dispatch({
      type: TERM,
      payload: '',
    });
    dispatch({
      type: RESULTS_DOWNLOAD_PROGRESS,
      payload: undefined,
    });
  };
}

export function clearQuery() {
  return function (dispatch) {
    dispatch({
      type: FILTER_RESULTS,
      payload: '',
    });
    dispatch({
      type: POS_TITLE,
      payload: '',
    });
    dispatch({
      type: QUERY,
      payload: '',
    });
  };
}

export function getQuery(pos_code, id, code, country) {
  // console.log('functionState: ' + pos_code);
  if (
    code === 'E_18' ||
    code === 'E_19' ||
    code === 'E_20' ||
    code === 'E_21' ||
    code === 'E_22' ||
    code === 'E_23' ||
    code === 'E_24'
  ) {
    // console.log(pos_code);
    var strArray = pos_code.split(' - ');
    pos_code = strArray[0];
    var pos_title = '';
    if (
      ((strArray[0].startsWith('1') && strArray[0].length !== 9) ||
        strArray[0].startsWith('2') ||
        strArray[0].startsWith('3')) &&
      strArray[0].length > 4
    ) {
      // console.log('opt 1');
      if (
        (code === 'E_19' ||
          code === 'E_18' ||
          code === 'E_20' ||
          code === 'E_21' ||
          code === 'E_22' ||
          code === 'E_23' ||
          code === 'E_24') &&
        pos_code > 10000000
      ) {
        // console.log('opt 2');
        pos_title = strArray[1] + ' - ' + strArray[2] + ' - ' + strArray[3];
      } else {
        // console.log('opt 3');
        pos_title = strArray[1] + ' - ' + strArray[2];
      }
      // console.log('3: ' + pos_title);feat
    } else if (
      code === 'E_18' ||
      code === 'E_19' ||
      ((code === 'E_20' || code === 'E_21' || code === 'E_22') &&
        (pos_code < 500000 || pos_code > 899999))
    ) {
      // console.log('opt 4');
      pos_title = strArray[1] + ' - ' + strArray[2];
      // console.log('1: ' + pos_title);
    } else if (code === 'E_20') {
      pos_title = strArray[1];
    } else {
      // console.log('opt 5');
      pos_title = strArray[1] + ' - ' + strArray[2];
      // console.log('2: ' + pos_title);
    }
    if (
      code === 'E_23' &&
      code === 'E_24' &&
      (pos_code < 100 || (pos_code >= 500000 && pos_code <= 899989))
    ) {
      pos_title = strArray[1];
    }
  }
  // console.log('returnStart: ' + pos_title);
  return function (dispatch) {
    axios
      .get(
        `/api/client/survey/${id}/results/basic_query/${pos_code}/${
          (country &&
            (code === 'S_INTL_22' ||
              code === 'S_INTL_23' ||
              code === 'S_INTL_24') &&
            ((pos_code > 300 && pos_code < 4000) || pos_code >= 100000)) ||
          (country &&
            (code === 'E_INTL_22' ||
              code === 'E_INTL_23' ||
              code === 'E_INTL_24') &&
            pos_code > 200 &&
            pos_code < 3000)
            ? '?country=' + country
            : ''
        }`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          },
        }
      )
      .then((response) => {
        dispatch({
          type: QUERY,
          payload: response.data,
        });
        // console.log("action posTitle: " + pos_title);
        if (
          code === 'E_18' ||
          code === 'E_19' ||
          code === 'E_20' ||
          code === 'E_21' ||
          code === 'E_22' ||
          code === 'E_23' ||
          code === 'E_24'
        ) {
          dispatch({
            type: POS_TITLE,
            payload: pos_title,
          });
        }
      });
  };
}

export function getResults(id) {
  return function (dispatch) {
    axios
      .get(`/api/client/survey/${id}/results/`, {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        dispatch({
          type: RESULTS,
          payload: response.data,
        });
        sessionStorage.setItem('survey_id', response.data.survey_guid);
      });
  };
}

export function pdfPrint(print) {
  return function (dispatch) {
    dispatch({
      type: PDF_PRINT,
      payload: print,
    });
  };
}

export function posCount(n) {
  return function (dispatch) {
    dispatch({
      type: POS_COUNT,
      payload: n,
    });
  };
}

export function searchTerm(term) {
  return function (dispatch) {
    dispatch({
      type: TERM,
      payload: term,
    });
  };
}

export function setCountry(country, changed) {
  return function (dispatch) {
    dispatch({
      type: COUNTRY,
      payload: country,
    });
    dispatch({
      type: CHANGED,
      payload: changed,
    });
  };
}

export function demoPosList() {
  return function (dispatch) {
    dispatch({
      type: RESULTS,
      payload: demoPosListData,
    });
  };
}

export function demoQuery(posCode) {
  return function (dispatch) {
    dispatch({
      type: QUERY,
      payload: demoQueryData.find((obj) => obj.pos_code === posCode),
    });
  };
}
