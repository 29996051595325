import {
  ACCT_VALUES,
  AUTH_USER,
  COMPANY_GUID,
  CSRF_TOKEN,
  FEATURES,
  PERMIT_RENDER,
  PRODUCT_BUYER,
  UNAUTH_USER,
  USER_GUID,
  USER_INFO,
} from '../actions/authentication';

export default function (state = {}, action) {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, error: '', authenticated: action.payload };
    case UNAUTH_USER:
      return { ...state, authenticated: action.payload };
    case PERMIT_RENDER:
      return { ...state, permitRender: action.payload };
    case PRODUCT_BUYER:
      return { ...state, productBuyer: action.payload };
    case COMPANY_GUID:
      return { ...state, companyGuid: action.payload };
    case USER_GUID:
      return { ...state, userGuid: action.payload };
    case USER_INFO:
      return { ...state, userInfo: action.payload };
    case ACCT_VALUES:
      return {
        ...state,
        acctVal: {
          username: action.payload.data.username,
          company: action.payload.data.name,
          firstname: action.payload.data.first_name,
          lastname: action.payload.data.last_name,
          email: action.payload.data.email,
          sq1: action.payload.data.security_q1,
          sq2: action.payload.data.security_q2,
        },
      };
    case CSRF_TOKEN:
      return { ...state, csrfToken: action.payload };
    case FEATURES:
      return {
        ...state,
        features: action.payload,
      };
    default:
      return state;
  }
}
