import {
  PARTICIPATION_CLEAR_SELECTION,
  PARTICIPATION_CONFIRMATION_TOGGLE,
  PARTICIPATION_INFORMATION,
  PARTICIPATION_SELECTION,
  PARTICIPATION_SUBMITTED_SUCCESS,
} from '../actions/participation';

const initialState = {
  selections: [],
  information: {},
  toggle: false,
  submitSuccess: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PARTICIPATION_CLEAR_SELECTION:
      return { ...state, selections: action.payload };
    case PARTICIPATION_CONFIRMATION_TOGGLE:
      return { ...state, toggle: action.payload };
    case PARTICIPATION_INFORMATION:
      return { ...state, information: action.payload };
    case PARTICIPATION_SELECTION:
      const existingIndex = state.selections.findIndex(
        (selection) => selection.survey_guid === action.payload.survey_guid
      );

      if (action.payload.response_id === null) {
        // Remove the selection if the option is null
        return {
          ...state,
          selections: state.selections.filter(
            (selection) => selection.survey_guid !== action.payload.survey_guid
          ),
        };
      }

      if (existingIndex > -1) {
        return {
          ...state,
          selections: state.selections.map((selection, index) =>
            index === existingIndex ? action.payload : selection
          ),
        };
      }
      return { ...state, selections: [...state.selections, action.payload] };
    case PARTICIPATION_SUBMITTED_SUCCESS:
      return { ...state, success: action.payload };
    default:
      return state;
  }
  // return state;
}
